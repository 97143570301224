import React from 'react'
import { ILXTeaser } from '../../../../../../types/cmsPlacement/LXTeaser'
import {
  StyledTextModuleContainer,
  CtaContentTextModule,
  IconContentTextModule,
  PreTitleTextModule,
  TextTextModule,
  StyledTitleTextModule,
  StyledWrapperTextModule,
} from '../TextModule.style'
import useCmsTeaserBanner from '../../../../../../hooks/useCmsBanner'
import CmsIcon from '../../../../CmsComponents/CmsIcon'
import CmsCta from '../../../../CmsComponents/CmsCta'
import { getTeaserOverlayTextAlign } from '../../../../../../utils/placements'
import { IViewType } from '../../../../../../types/cmsPlacement/ViewType'
import useReplaceLocaleInCMSUrl from '@hooks/useReplaceLocaleInCMSUrl'

const TextModuleTeaser: React.FC<{
  item: ILXTeaser
  viewType: IViewType
  teaserIndex?: number
  bannerIndex?: number
}> = ({ item, viewType, teaserIndex, bannerIndex }) => {
  const {
    teaserIcon,
    promoteToH1,
    teaserPreTitle,
    teaserTitleValue,
    teaserTitle,
    teaserOverlaySettingsValue,
    teaserText,
    teaserTextValue,
    teaserLXCallToActionSettings,
    teaserOverlayStyleValue,
    teaserOverlayTextAlignValue,
    isFrameAdvisor,
  } = useCmsTeaserBanner({ item, viewType })
  const replaceLocaleInCmsUrls = useReplaceLocaleInCMSUrl()

  return (
    <StyledWrapperTextModule
      teaserOverlayStyle={teaserOverlayStyleValue}
      overlaySettings={teaserOverlaySettingsValue!}
      overlayTextAlign={getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
    >
      <StyledTextModuleContainer>
        {teaserIcon && (
          <IconContentTextModule>
            <CmsIcon teaserIcon={teaserIcon} />
          </IconContentTextModule>
        )}
        {teaserPreTitle && (
          <PreTitleTextModule
            data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
            variant={promoteToH1 ? 'h1' : 'h2'}
            fontWeight={'600'}
            isUppercased
          >
            {teaserPreTitle}
          </PreTitleTextModule>
        )}
        {teaserTitleValue && (
          <StyledTitleTextModule
            data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
            variant={promoteToH1 ? 'h1' : 'h6'}
          >
            {teaserTitleValue}
          </StyledTitleTextModule>
        )}
        {teaserTextValue && (
          <TextTextModule
            data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
            dangerouslySetInnerHTML={{ __html: replaceLocaleInCmsUrls(teaserTextValue) }}
          />
        )}
        {teaserLXCallToActionSettings.length > 0 && (
          <CtaContentTextModule data-element-id={isFrameAdvisor && 'X_X_Footer_FrameGenius_Open'}>
            {teaserLXCallToActionSettings.map((actionSettings, index) => (
              <CmsCta
                actionSettings={actionSettings}
                data-element-id={`X_X_${teaserIndex}Placement_Banner${bannerIndex}_CTA${index + 1}`}
                key={`cms-content__text-module--cta-${index}`}
              />
            ))}
          </CtaContentTextModule>
        )}
      </StyledTextModuleContainer>
    </StyledWrapperTextModule>
  )
}

export default TextModuleTeaser
